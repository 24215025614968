// Import semiprofile philosopher images
import sp_MarcusAurelius from './images/marbleStatuePictures/Semi-profile/MarcusAureliusWhiteTranslucentBG.png';
import sp_FriedrichNietzsche from './images/marbleStatuePictures/Semi-profile/FriedrichNietzscheWhiteTranslucentBG.png';
import sp_SimoneDeBeauvoir from './images/marbleStatuePictures/Semi-profile/SimoneDeBeauvoirWhiteTranslucentBG.png';
import sp_Confucius from './images/marbleStatuePictures/Semi-profile/ConfuciusWhiteTranslucentBG.png';
import sp_ImmanuelKant from './images/marbleStatuePictures/Semi-profile/ImmanuelKantWhiteTranslucentBG.png';
import sp_ReneDescartes from './images/marbleStatuePictures/Semi-profile/RenéDescartesWhiteTranslucentBG.png';
import sp_DavidHume from './images/marbleStatuePictures/Semi-profile/DavidHumeWhiteTranslucentBG.png';
import sp_WillardVanOrmanQuine from './images/marbleStatuePictures/Semi-profile/WilliardVanOmarQuineWhiteTranslucentBG.png';
import sp_Socrates from './images/marbleStatuePictures/Semi-profile/SocratesWhiteTranslucentBG.png';
import sp_LaoTzu from './images/marbleStatuePictures/Semi-profile/LaoTsuWhiteTranslucentBG.png';
import sp_SorenKierkegaard from './images/marbleStatuePictures/Semi-profile/SorenKirkegaardWhiteTranslucentBG.png';
import sp_Diogenes from './images/marbleStatuePictures/Semi-profile/DiogenesWhiteTranslucentBG.png';
import sp_ArthurSchopenhauer from './images/marbleStatuePictures/Semi-profile/SchoupenhauerWhiteTranslucentBG.png';
import sp_AlbertCamus from './images/marbleStatuePictures/Semi-profile/AlbertCamusWhiteTranslucentBG.png';
import sp_Aristotle from './images/marbleStatuePictures/Semi-profile/AristotleWhiteTranslucentBG.png';
import sp_HannahArendt from './images/marbleStatuePictures/Semi-profile/HannahArendtWhiteTranslucentBG.png';
import sp_Plato from './images/marbleStatuePictures/Semi-profile/PlatoWhiteTranslucentBG.png';
import sp_SimoneWeil from './images/marbleStatuePictures/Semi-profile/SimoneWeilWhiteTranslucentBG.png';
import sp_IrisMurdoch from './images/marbleStatuePictures/Semi-profile/IrisMurdochWhiteTranslucentBG.png';
import sp_MaryMidgley from './images/marbleStatuePictures/Semi-profile/MaryMidgleyWhiteTranslucentBG.png';
import sp_Epictetus from './images/marbleStatuePictures/Semi-profile/EpictetusWhiteTranslucentBG.png';
import sp_PhilippaFoot from './images/marbleStatuePictures/Semi-profile/PhilippaFootWhiteTranslucentBG.png';
import sp_JeanPaulSartre from './images/marbleStatuePictures/Semi-profile/SartreWhiteTranslucentBG.png';
import sp_AynRand from './images/marbleStatuePictures/Semi-profile/AynRandWhiteTranslucentBG.png';
import sp_GEMAnscombe from './images/marbleStatuePictures/Semi-profile/GEMAnscombeWhiteTranslucentBG.png';
import sp_JeremyBentham from './images/marbleStatuePictures/Semi-profile/JeremyBenthamWhiteTranslucentBG.png';
import sp_ErichFromm from './images/marbleStatuePictures/Semi-profile/ErichFrommWhiteTranslucentBG.png';
import sp_Epicurus from './images/marbleStatuePictures/Semi-profile/EpicurusWhiteTranslucentBG.png';
import sp_MiyamotoMusashi from './images/marbleStatuePictures/Semi-profile/MiyamotoMusashiWhiteTranslucentBG.png';
import sp_NiccoloMachiavelli from './images/marbleStatuePictures/Semi-profile/MachiavelliWhiteTranslucentBG.png';
import sp_GeorgWilhelmFriedrichHegel from './images/marbleStatuePictures/Semi-profile/HegelWhiteTranslucentBG.png';
import sp_KarlMarx from './images/marbleStatuePictures/Semi-profile/KarlMarxWhiteTranslucentBG.png';
import sp_JohnLocke from './images/marbleStatuePictures/Semi-profile/JohnLockeWhiteTranslucentBG.png';
import sp_LudwigWittgenstein from './images/marbleStatuePictures/Semi-profile/LudwigWittgensteinWhiteTranslucentBG.png';
import sp_JohnStuartMill from './images/marbleStatuePictures/Semi-profile/JohnStuartMillWhiteTranslucentBG.png';
import sp_MichelDeMontaigne from './images/marbleStatuePictures/Semi-profile/MichelMontaigneWhiteTranslucentBG.png';
import sp_GillesDeleuze from './images/marbleStatuePictures/Semi-profile/GillesDeleuzeWhiteTranslucentBG.png';
import sp_Seneca from './images/marbleStatuePictures/Semi-profile/SenecaWhiteTranslucentBG.png';
import sp_MaryWollstonecraft from './images/marbleStatuePictures/Semi-profile/MaryWollstonecraftWhiteTranslucentBG.png';
import sp_MichelFoucault from './images/marbleStatuePictures/Semi-profile/MichelFoucaultWhiteTranslucentBG.png';
import sp_bellhooks from './images/marbleStatuePictures/Semi-profile/bellhooksWhiteTranslucentBG.png';

// Import front-facing philosopher images
import ff_MarcusAurelius from './images/marbleStatuePictures/FrontFacing/MarcusAureliusWhiteBG.jpg';
import ff_FriedrichNietzsche from './images/marbleStatuePictures/FrontFacing/FriedrichNietzscheWhiteBG.jpg';
import ff_SimoneDeBeauvoir from './images/marbleStatuePictures/FrontFacing/SimoneDeBeauvoirWhiteBG.jpg';
import ff_Confucius from './images/marbleStatuePictures/FrontFacing/ConfuciusWhiteBG.jpg';
import ff_ImmanuelKant from './images/marbleStatuePictures/FrontFacing/ImmanuelKantWhiteBG.jpg';
import ff_ReneDescartes from './images/marbleStatuePictures/FrontFacing/ReneDescartesWhiteBG.jpg';
import ff_DavidHume from './images/marbleStatuePictures/FrontFacing/DavidHumeWhiteBG.jpg';
import ff_WillardVanOrmanQuine from './images/marbleStatuePictures/FrontFacing/WillardVanOrmanQuineWhiteBG.jpg';
import ff_Socrates from './images/marbleStatuePictures/FrontFacing/SocratesWhiteBG.jpg';
import ff_LaoTzu from './images/marbleStatuePictures/FrontFacing/LaoTzuWhiteBG.jpg';
import ff_SorenKierkegaard from './images/marbleStatuePictures/FrontFacing/SorenKierkegaardWhiteBG.jpg';
import ff_Diogenes from './images/marbleStatuePictures/FrontFacing/DiogenesWhiteBG.jpg';
import ff_ArthurSchopenhauer from './images/marbleStatuePictures/FrontFacing/ArthurSchopenhauerWhiteBG.jpg';
import ff_AlbertCamus from './images/marbleStatuePictures/FrontFacing/AlbertCamusWhiteBG.jpg';
import ff_Aristotle from './images/marbleStatuePictures/FrontFacing/AristotleWhiteBG.jpg';
import ff_HannahArendt from './images/marbleStatuePictures/FrontFacing/HannahArendtWhiteBG.jpg';
import ff_Plato from './images/marbleStatuePictures/FrontFacing/PlatoWhiteBG.jpg';
import ff_SimoneWeil from './images/marbleStatuePictures/FrontFacing/SimoneWeilWhiteBG.jpg';
import ff_IrisMurdoch from './images/marbleStatuePictures/FrontFacing/IrisMurdochWhiteBG.jpg';
import ff_MaryMidgley from './images/marbleStatuePictures/FrontFacing/MaryMidgleyWhiteBG.jpg';
import ff_Epictetus from './images/marbleStatuePictures/FrontFacing/EpictetusWhiteBG.jpg';
import ff_PhilippaFoot from './images/marbleStatuePictures/FrontFacing/PhilippaFootWhiteBG.jpg';
import ff_JeanPaulSartre from './images/marbleStatuePictures/FrontFacing/JeanPaulSartreWhiteBG.jpg';
import ff_AynRand from './images/marbleStatuePictures/FrontFacing/AynRandWhiteBG.jpg';
import ff_GEMAnscombe from './images/marbleStatuePictures/FrontFacing/GEMAnscombeWhiteBG.jpg';
import ff_JeremyBentham from './images/marbleStatuePictures/FrontFacing/JeremyBenthamWhiteBG.jpg';
import ff_ErichFromm from './images/marbleStatuePictures/FrontFacing/ErichFrommWhiteBG.jpg';
import ff_Epicurus from './images/marbleStatuePictures/FrontFacing/EpicurusWhiteBG.jpg';
import ff_MiyamotoMusashi from './images/marbleStatuePictures/FrontFacing/MiyamotoMusashiWhiteBG.jpg';
import ff_NiccoloMachiavelli from './images/marbleStatuePictures/FrontFacing/NiccoloMachiavelliWhiteBG.jpg';
import ff_GeorgWilhelmFriedrichHegel from './images/marbleStatuePictures/FrontFacing/GeorgWilhelmFriedrichHegelWhiteBG.jpg';
import ff_KarlMarx from './images/marbleStatuePictures/FrontFacing/KarlMarxWhiteBG.jpg';
import ff_JohnLocke from './images/marbleStatuePictures/FrontFacing/JohnLockeWhiteBG.jpg';
import ff_LudwigWittgenstein from './images/marbleStatuePictures/FrontFacing/LudwigWittgensteinWhiteBG.jpg';
import ff_JohnStuartMill from './images/marbleStatuePictures/FrontFacing/JohnStuartMillWhiteBG.jpg';
import ff_MichelDeMontaigne from './images/marbleStatuePictures/FrontFacing/MichelDeMontaigneWhiteBG.jpg';
import ff_GillesDeleuze from './images/marbleStatuePictures/FrontFacing/GillesDeleuzeWhiteBG.jpg';
import ff_Seneca from './images/marbleStatuePictures/FrontFacing/SenecaWhiteBG.jpg';
import ff_MaryWollstonecraft from './images/marbleStatuePictures/FrontFacing/MaryWollstonecraftWhiteBG.jpg';
import ff_MichelFoucault from './images/marbleStatuePictures/FrontFacing/MichelFoucaultWhiteBG.jpg';
import ff_bellhooks from './images/marbleStatuePictures/FrontFacing/bellhooksWhiteBG.jpg';

// Import Backdrop colors
import color1 from './images/backdrop-For-PhilosopherCards/Backdrop1.svg';
import color2 from './images/backdrop-For-PhilosopherCards/Backdrop2.svg';
import color3 from './images/backdrop-For-PhilosopherCards/Backdrop3.svg';
import color4 from './images/backdrop-For-PhilosopherCards/Backdrop4.svg';
import color5 from './images/backdrop-For-PhilosopherCards/Backdrop5.svg';
import color6 from './images/backdrop-For-PhilosopherCards/Backdrop6.svg';
import color7 from './images/backdrop-For-PhilosopherCards/Backdrop7.svg';
import color8 from './images/backdrop-For-PhilosopherCards/Backdrop8.svg';
import color9 from './images/backdrop-For-PhilosopherCards/Backdrop9.svg';
import color10 from './images/backdrop-For-PhilosopherCards/Backdrop10.svg';

export const philosophers = [
  {
    id: 1,
    name: 'Marcus Aurelius',
    backgroundColor: color1,
    sp_image: sp_MarcusAurelius,
    ff_image: ff_MarcusAurelius,
    epithet: 'Roman Emperor of Self-Mastery',
    lifeSpan: '(121-180)',
    descriptiveWords: ['Stoic', 'Compassionate', 'Pragmatic'],
    bestQuote: 'The happiness of your life depends upon the quality of your thought',
    nationality: '🇮🇹 Roman',
    accomplishments: [
      'Wrote "Meditations," a cornerstone of Stoic philosophy still widely read today',
      'Effectively ruled the Roman Empire during a period of multiple crises',
      'Balanced the roles of philosopher and emperor, applying Stoic principles to governance'
    ],
    lifeStory: 'marcus-aurelius.md',
    relatedPhilosophers: [21, 38, 17, 9]
  },
  {
    id: 2,
    name: 'Friedrich Nietzsche',
    backgroundColor: color2,
    sp_image: sp_FriedrichNietzsche,
    ff_image: ff_FriedrichNietzsche,
    epithet: 'Radical Critic of Ideals',
    lifeSpan: '(1844-1900)',
    descriptiveWords: ['Ambitious', 'Rebel', 'Poetic'],
    bestQuote: 'He who has a why to live can bear almost any how.',
    nationality: '🏳️ Officially Stateless',
    accomplishments: [
      'Introduced the concept of "übermensch" (superman), inspiring discussions on human potential',
      'Challenged traditional morality with his idea of "beyond good and evil"',
      'Influenced existentialism and postmodernism with his critique of absolute truths'
    ],
    lifeStory: 'friedrich-nietzsche.md',
    relatedPhilosophers: [12, 11, 31]
  },
  {
    id: 3,
    name: 'Simone de Beauvoir',
    backgroundColor: color3,
    sp_image: sp_SimoneDeBeauvoir,
    ff_image: ff_SimoneDeBeauvoir,
    epithet: 'Existentialist Voice of Equality',
    lifeSpan: '(1908-1986)',
    descriptiveWords: ['Existentialist', 'Feminist', 'Writer'],
    bestQuote: 'One is not born, but rather becomes, a woman.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Wrote "The Second Sex," a foundational text of modern feminism',
      'Advanced existentialist philosophy, emphasizing individual freedom and responsibility',
      'Challenged societal norms and gender roles through her writing and lifestyle'
    ],
    lifeStory: 'simone-de-beauvoir.md',
    relatedPhilosophers: [23, 14, 39, 11]
  },
  {
    id: 4,
    name: 'Confucius',
    backgroundColor: color4,
    sp_image: sp_Confucius,
    ff_image: ff_Confucius,
    epithet: 'Chinese Sage of Harmony',
    lifeSpan: '(551-479 BC)',
    descriptiveWords: ['Moralist', 'Respectful', 'Ritualistic'],
    bestQuote: 'In three ways we become wise: first, by reflection, the noblest; second, by imitation, the easiest; and third by experience, the bitterest.',
    nationality: '🇨🇳 Chinese',
    accomplishments: [
      'Developed an ethical and philosophical system that shaped East Asian culture for millennia',
      'Pioneering the concept of public education and making it accessible to all social classes',
      'Training a large number of devoted disciples (over 3,000 according to some accounts)'
    ],
    lifeStory: 'confucius.md',
    relatedPhilosophers: [10, 15]
  },
  {
    id: 5,
    name: 'Immanuel Kant',
    backgroundColor: color5,
    sp_image: sp_ImmanuelKant,
    ff_image: ff_ImmanuelKant,
    epithet: 'Critic of Pure Reason',
    lifeSpan: '(1724-1804)',
    descriptiveWords: ['Kantian', ' Epistemologist', ' Ethicist'],
    bestQuote: 'Live your life as though your every act were to become a universal law',
    nationality: '🇩🇪 German',
    accomplishments: [
      'Formulated the Categorical Imperative, a cornerstone of moral philosophy',
      'Wrote "Critique of Pure Reason," shaping modern epistemology and metaphysics',
      'Established a comprehensive system that bridges rationalism and empiricism'
    ],
    lifeStory: 'immanuel-kant.md',
    relatedPhilosophers: [1, 6, 24, 8]
  },
  {
    id: 6,
    name: 'René Descartes',
    backgroundColor: color6,
    sp_image: sp_ReneDescartes,
    ff_image: ff_ReneDescartes,
    epithet: 'Father of Modern Philosophy',
    lifeSpan: '(1596-1650)',
    descriptiveWords: ['Rationalist', ' Dualist', ' Doubting'],
    bestQuote: 'Cogito, ergo sum (I think, therefore I am).',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Pioneered the use of reason in the pursuit of scientific and philosophical truth',
      'Developed Cartesian dualism, separating mind and body',
      'Laid the groundwork for modern geometry and philosophy of mind'
    ],
    lifeStory: 'rene-descartes.md',
    relatedPhilosophers: [5, 7, 11]
  },
  {
    id: 7,
    name: 'David Hume',
    backgroundColor: color7,
    sp_image: sp_DavidHume,
    ff_image: ff_DavidHume,
    epithet: 'Master of Human Understanding',
    lifeSpan: '(1711-1776)',
    descriptiveWords: ['Empiricist', ' Sceptic', ' Ethicist'],
    bestQuote: 'A wise man, therefore, proportions his belief to the evidence.',
    nationality: '🇬🇧 Scottish',
    accomplishments: [
      'Critiqued the concept of causation and argued that human knowledge arises only from sensory experiences',
      'Wrote "A Treatise of Human Nature," influencing later empirical philosophers',
      'Advanced the philosophy of empiricism and skepticism'
    ],
    lifeStory: 'david-hume.md',
    relatedPhilosophers: [6, 10, 34, 22]
  },
  {
    id: 8,
    name: 'W.V.O. Quine',
    backgroundColor: color8,
    sp_image: sp_WillardVanOrmanQuine,
    ff_image: ff_WillardVanOrmanQuine,
    epithet: 'Challenger of Analytic Tradition',
    lifeSpan: '(1908-2000)',
    descriptiveWords: ['Empiricist', ' Holistic', ' Ontologist'],
    bestQuote: 'Science is not a substitute for common sense, but an extension of it.',
    nationality: '🇺🇸 American',
    accomplishments: [
      'Critiqued the analytic-synthetic distinction in his work "Two Dogmas of Empiricism"',
      'Developed the Quine-Putnam indispensability argument in philosophy of mathematics',
      'Contributed significantly to the philosophy of language and logic'
    ],
    lifeStory: 'willard-van-orman-quine.md',
    relatedPhilosophers: [7, 25, 27]
  },
  {
    id: 9,
    name: 'Socrates',
    backgroundColor: color9,
    sp_image: sp_Socrates,
    ff_image: ff_Socrates,
    epithet: 'Founder of Western Philosophy',
    lifeSpan: '(469-399 BC)',
    descriptiveWords: ['Questioner', ' Gadfly', 'Martyr'],
    bestQuote: 'The only true wisdom is in knowing you know nothing.',
    nationality: '🇬🇷 Greek',
    accomplishments: [
      'Developed the Socratic method, a form of cooperative argumentative dialogue',
      'Influenced Western philosophy profoundly despite leaving no written works',
      'Executed for his philosophical teachings and criticisms of Athenian society'
    ],
    lifeStory: 'socrates.md',
    relatedPhilosophers: [17, 4, 13]
  },
  {
    id: 10,
    name: 'Lao Tzu',
    backgroundColor: color10,
    sp_image: sp_LaoTzu,
    ff_image: ff_LaoTzu,
    epithet: 'Sage of the Tao',
    lifeSpan: '(6th century BC)',
    descriptiveWords: ['Taoist', ' Poet', ' Mystic'],
    bestQuote: 'The journey of a thousand miles begins with a single step.',
    nationality: '🇨🇳 Chinese',
    accomplishments: [
      'Authored the "Tao Te Ching," a fundamental text in Taoist philosophy',
      'Promoted living in harmony with the Tao, the fundamental nature of the universe',
      'Influenced Chinese religion, politics, and culture for centuries'
    ],
    lifeStory: 'lao-tzu.md',
    relatedPhilosophers: [4, 35, 22]
  },
  {
    id: 11,
    name: 'Søren Kierkegaard',
    backgroundColor: color1,
    sp_image: sp_SorenKierkegaard,
    ff_image: ff_SorenKierkegaard,
    epithet: 'Father of Existentialism',
    lifeSpan: '(1813-1855)',
    descriptiveWords: ['Existentialist', ' Writer', ' Theologian'],
    bestQuote: 'Life can only be understood backwards; but it must be lived forwards.',
    nationality: '🇩🇰 Danish',
    accomplishments: [
      'Pioneered existentialist thought, emphasizing individual choice and faith',
      'Critiqued institutionalized Christianity and the church',
      'Authored "Fear and Trembling" and "The Sickness Unto Death," exploring faith and despair'
    ],
    lifeStory: 'soren-kierkegaard.md',
    relatedPhilosophers: [2, 14, 3, 23, 29]
  },
  {
    id: 12,
    name: 'Diogenes',
    backgroundColor: color2,
    sp_image: sp_Diogenes,
    ff_image: ff_Diogenes,
    epithet: 'Shameless dog of Social Norms',
    lifeSpan: '(412-323 BC)',
    descriptiveWords: ['Cynic', ' Provocateur', ' Homeless'],
    bestQuote: 'It is not that I am mad, it is only that my head is different from yours.',
    nationality: '🇬🇷 Greek',
    accomplishments: [
      'Founder of Cynicism, promoting a life lived in accordance with nature',
      'Critiqued social conventions and materialism',
      'Famous for his ascetic lifestyle and sharp wit'
    ],
    lifeStory: 'diogenes.md',
    relatedPhilosophers: [9, 4, 17, 13]
  },
  {
    id: 13,
    name: 'Arthur Schopenhauer',
    backgroundColor: color3,
    sp_image: sp_ArthurSchopenhauer,
    ff_image: ff_ArthurSchopenhauer,
    epithet: 'Pessimistic Thinker of Suffering',
    lifeSpan: '(1788-1860)',
    descriptiveWords: ['Pessimist', ' Aesthetic', ' Writer'],
    bestQuote: 'Talent hits a target no one else can hit. Genius hits a target no one else can see.',
    nationality: '🇩🇪 German',
    accomplishments: [
      'Developed a metaphysical system based on the concept of the will',
      'Influenced existentialism, psychology, and the arts',
      'Authored "The World as Will and Representation," exploring the primacy of the will'
    ],
    lifeStory: 'arthur-schopenhauer.md',
    relatedPhilosophers: [2, 11, 21, 31]
  },
  {
    id: 14,
    name: 'Albert Camus',
    backgroundColor: color4,
    sp_image: sp_AlbertCamus,
    ff_image: ff_AlbertCamus,
    epithet: 'Rebellious Voice of the Absurd',
    lifeSpan: '(1913-1960)',
    descriptiveWords: ['Absurdist', ' Rebel', ' Existentialist'],
    bestQuote: 'The only way to deal with an unfree world is to become so absolutely free that your very existence is an act of rebellion.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Explored the concept of the absurd in works like "The Stranger" and "The Myth of Sisyphus"',
      'Received the Nobel Prize in Literature in 1957',
      'Advocated for human rights and critiqued totalitarianism'
    ],
    lifeStory: 'albert-camus.md',
    relatedPhilosophers: [13, 3, 39]
  },
  {
    id: 15,
    name: 'Aristotle',
    backgroundColor: color5,
    sp_image: sp_Aristotle,
    ff_image: ff_Aristotle,
    epithet: 'The Polymath of Antiquity',
    lifeSpan: '(384-322 BC)',
    descriptiveWords: ['Realist', ' Scientist', ' Teacher'],
    bestQuote: 'We are what we repeatedly do… therefore excellence is not an act, but a habit.',
    nationality: '🇬🇷 Greek',
    accomplishments: [
      'Founded the Lyceum and wrote extensively on philosophy, science, and politics',
      'Developed formal logic and syllogism, influencing Western thought',
      'Tutored Alexander the Great, shaping his intellectual development'
    ],
    lifeStory: 'aristotle.md',
    relatedPhilosophers: [4, 9, 17]
  },
  {
    id: 16,
    name: 'Hannah Arendt',
    backgroundColor: color6,
    sp_image: sp_HannahArendt,
    ff_image: ff_HannahArendt,
    epithet: 'Analyst of Totalitarian Evils',
    lifeSpan: '(1906-1975)',
    descriptiveWords: ['Political', ' Theorist', ' Writer'],
    bestQuote: 'The sad truth is that most evil is done by people who never make up their minds to be good or evil.',
    nationality: '🇩🇪🇺🇸 German/American',
    accomplishments: [
      'Analyzed the nature of power, authority, and totalitarianism in "The Origins of Totalitarianism"',
      'Explored the concept of the "banality of evil" in her report on the Eichmann trial',
      'Contributed to political theory and the understanding of modern political phenomena'
    ],
    lifeStory: 'hannah-arendt.md',
    relatedPhilosophers: [2, 3, 39, 11]
  },
  {
    id: 17,
    name: 'Plato',
    backgroundColor: color7,
    sp_image: sp_Plato,
    ff_image: ff_Plato,
    epithet: 'The Eternal Footnote',
    lifeSpan: '(428-348 BC)',
    descriptiveWords: ['Idealist', ' Teacher', ' Theorist'],
    bestQuote: 'Be kind, for everyone you meet is fighting a hard battle',
    nationality: '🇬🇷 Greek',
    accomplishments: [
      'Founded the Academy in Athens, one of the earliest institutions of higher learning',
      'Authored dialogues like "The Republic," exploring justice, politics, and education',
      'Influenced Western philosophy and science through his theory of Forms'
    ],
    lifeStory: 'plato.md',
    relatedPhilosophers: [9, 15, 13, 31]
  },
  {
    id: 18,
    name: 'Simone Weil',
    backgroundColor: color8,
    sp_image: sp_SimoneWeil,
    ff_image: ff_SimoneWeil,
    epithet: 'Activist of Compassion',
    lifeSpan: '(1909-1943)',
    descriptiveWords: ['Mystic', ' Activist', ' Thinker'],
    bestQuote: 'Attention is the rarest and purest form of generosity.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Explored the intersection of mysticism, political philosophy, and social activism',
      'Authored "Gravity and Grace," reflecting on spirituality and human suffering',
      'Participated in labor movements and the French Resistance during World War II'
    ],
    lifeStory: 'simone-weil.md',
    relatedPhilosophers: [14, 3, 39]
  },
  {
    id: 19,
    name: 'Iris Murdoch',
    backgroundColor: color9,
    sp_image: sp_IrisMurdoch,
    ff_image: ff_IrisMurdoch,
    epithet: 'Explorer of Human Goodness',
    lifeSpan: '(1919-1999)',
    descriptiveWords: ['Moralist', ' Novelist', ' Writer'],
    bestQuote: 'Love is the extremely difficult realization that something other than oneself is real.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Authored philosophical novels exploring themes of morality and human relationships',
      'Developed ideas on moral philosophy and the role of art in ethical life',
      'Contributed to discussions on goodness, virtue, and moral psychology'
    ],
    lifeStory: 'iris-murdoch.md',
    relatedPhilosophers: [22, 19, 20, 15]
  },
  {
    id: 20,
    name: 'Mary Midgley',
    backgroundColor: color10,
    sp_image: sp_MaryMidgley,
    ff_image: ff_MaryMidgley,
    epithet: 'Expander of Moral Horizons',
    lifeSpan: '(1919-2018)',
    descriptiveWords: ['Moralist', ' Critic', ' Writer'],
    bestQuote: 'Morally as well as physically, there is only one world, and we all have to live in it.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Critiqued reductionist approaches to science and human nature',
      'Authored "Beast and Man," exploring the relationship between humans and animals',
      'Engaged in public philosophy, addressing ethical issues in contemporary society'
    ],
    lifeStory: 'mary-midgley.md',
    relatedPhilosophers: [19, 25, 22, 35]
  },
  {
    id: 21,
    name: 'Epictetus',
    backgroundColor: color1,
    sp_image: sp_Epictetus,
    ff_image: ff_Epictetus,
    epithet: 'Master of Resilience',
    lifeSpan: '(50-135)',
    descriptiveWords: ['Stoic', ' Freed slave', ' Teacher'],
    bestQuote: 'It\'s not what happens to you, but how you react to it that matters.',
    nationality: '🇹🇷🇬🇷 Turkish/Greek',
    accomplishments: [
      'Taught Stoic philosophy emphasizing resilience and self-control',
      'His teachings were recorded in the "Discourses" and "Enchiridion"',
      'Influenced later Stoics and modern self-help philosophies'
    ],
    lifeStory: 'epictetus.md',
    relatedPhilosophers: [1, 9, 38, 15, 13]
  },
  {
    id: 22,
    name: 'Philippa Foot',
    backgroundColor: color2,
    sp_image: sp_PhilippaFoot,
    ff_image: ff_PhilippaFoot,
    epithet: 'Reviviver of Virtue Ethicist',
    lifeSpan: '(1920-2010)',
    descriptiveWords: ['Moralist', ' Ethecist', ' Teacher'],
    bestQuote: 'Moral dilemmas do not disappear simply by learning more facts.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Developed the theory of virtue ethics, emphasizing character and morality',
      'Critiqued consequentialism and deontology in her works',
      'Authored "Natural Goodness," exploring the nature of moral judgments'
    ],
    lifeStory: 'philippa-foot.md',
    relatedPhilosophers: [20, 25, 19, 15]
  },
  {
    id: 23,
    name: 'Jean-Paul Sartre',
    backgroundColor: color3,
    sp_image: sp_JeanPaulSartre,
    ff_image: ff_JeanPaulSartre,
    epithet: 'Existentialist Voice of Liberation',
    lifeSpan: '(1905-1980)',
    descriptiveWords: ['Existentialist', ' Critic', ' Playwright'],
    bestQuote: 'Existence precedes essence.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Pioneered existentialist philosophy, emphasizing freedom and responsibility',
      'Authored "Being and Nothingness," a fundamental text in existentialism',
      'Influenced political thought and activism through his works and actions'
    ],
    lifeStory: 'jean-paul-sartre.md',
    relatedPhilosophers: [2, 3, 14]
  },
  {
    id: 24,
    name: 'Ayn Rand',
    backgroundColor: color4,
    sp_image: sp_AynRand,
    ff_image: ff_AynRand,
    epithet: 'Champion of Rational Self-Interest',
    lifeSpan: '(1905-1982)',
    descriptiveWords: ['Objectivist', ' Novelist', ' Writer'],
    bestQuote: 'The question isn’t who is going to let me; it’s who is going to stop me.',
    nationality: '🇷🇺🇺🇸 Russian/American',
    accomplishments: [
      'Developed the philosophy of Objectivism, emphasizing rational self-interest',
      'Authored "Atlas Shrugged" and "The Fountainhead," promoting individualism and capitalism',
      'Influenced libertarian and conservative movements in the United States'
    ],
    lifeStory: 'ayn-rand.md',
    relatedPhilosophers: [6, 2, 25]
  },
  {
    id: 25,
    name: 'G.E.M. Anscombe',
    backgroundColor: color5,
    sp_image: sp_GEMAnscombe,
    ff_image: ff_GEMAnscombe,
    epithet: 'Champion of Virtue Ethics',
    lifeSpan: '(1919-2001)',
    descriptiveWords: ['Analytic', ' Ethicist', ' Logician'],
    bestQuote: 'Those who try to make room for sex as mere casual enjoyment pay the penalty: they become shallow.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Revived interest in virtue ethics with her work "Modern Moral Philosophy"',
      'Critiqued consequentialism and defended moral absolutism',
      'Influenced ethical theory, philosophy of action, and philosophy of mind'
    ],
    lifeStory: 'gem-anscombe.md',
    relatedPhilosophers: [22, 24, 29]
  },
  {
    id: 26,
    name: 'Jeremy Bentham',
    backgroundColor: color6,
    sp_image: sp_JeremyBentham,
    ff_image: ff_JeremyBentham,
    epithet: 'Father of Utilitarianism',
    lifeSpan: '(1748-1832)',
    descriptiveWords: ['Utilitarian', ' Reformer', ' Theorist'],
    bestQuote: 'The greatest happiness of the greatest number is the foundation of morals and legislation.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Founded the philosophical school of Utilitarianism',
      'Advocated for legal and social reforms, including animal rights and prison reform',
      'Developed the idea of the Panopticon, influencing discussions on surveillance and power'
    ],
    lifeStory: 'jeremy-bentham.md',
    relatedPhilosophers: [24, 6, 26]
  },
  {
    id: 27,
    name: 'Erich Fromm',
    backgroundColor: color7,
    sp_image: sp_ErichFromm,
    ff_image: ff_ErichFromm,
    epithet: 'Humanist thinker of Love',
    lifeSpan: '(1900-1980)',
    descriptiveWords: ['Psychoanalyst', 'Humanist', 'Writer'],
    bestQuote: 'The task we must set for ourselves is not to feel secure, but to be able to tolerate insecurity.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Explored the intersection of psychology and society in works like "Escape from Freedom"',
      'Critiqued authoritarianism and promoted humanistic ethics',
      'Contributed to the Frankfurt School and discussions on social psychology'
    ],
    lifeStory: 'erich-fromm.md',
    relatedPhilosophers: [14, 2, 28]
  },
  {
    id: 28,
    name: 'Epicurus',
    backgroundColor: color8,
    sp_image: sp_Epicurus,
    ff_image: ff_Epicurus,
    epithet: 'Seeker of happiness',
    lifeSpan: '(341-270 BC)',
    descriptiveWords: ['Epicureanist', ' Teacher', ' Founder'],
    bestQuote: 'Death does not concern us, because as long as we exist, death is not here. And when it does come, we no longer exist.',
    nationality: '🇬🇷 Greek',
    accomplishments: [
      'Founded Epicureanism, advocating for a life of moderate pleasure and absence of pain',
      'Critiqued superstition and divine intervention in human affairs',
      'Promoted atomism and materialism in his philosophical teachings'
    ],
    lifeStory: 'epicurus.md',
    relatedPhilosophers: [4, 9, 29]
  },
  {
    id: 29,
    name: 'Miyamoto Musashi',
    backgroundColor: color9,
    sp_image: sp_MiyamotoMusashi,
    ff_image: ff_MiyamotoMusashi,
    epithet: 'Sage of the Sword',
    lifeSpan: '(1584-1645)',
    descriptiveWords: ['Ronin', 'Strategist', 'Artist'],
    bestQuote: 'It may seem difficult at first, but all things are difficult at first.',
    nationality: '🇯🇵 Japanese',
    accomplishments: [
      'Authored "The Book of Five Rings," a classic text on strategy, tactics, and philosophy',
      'Undefeated in more than 60 duels to the death, thus recognized a legendary swordsman',
      'Influenced martial arts, strategy, and Japanese culture'
    ],
    lifeStory: 'miyamoto-musashi.md',
    relatedPhilosophers: [4, 8, 28]
  },
  {
    id: 30,
    name: 'Niccolò Machiavelli',
    backgroundColor: color10,
    sp_image: sp_NiccoloMachiavelli,
    ff_image: ff_NiccoloMachiavelli,
    epithet: 'The Prince\'s Tutor',
    lifeSpan: '(1469-1527)',
    descriptiveWords: ['Political', 'Pragmatic', 'Strategist'],
    bestQuote: 'It is better to be feared than loved, if you cannot be both.',
    nationality: '🇮🇹 Italian',
    accomplishments: [
      'Authored "The Prince," a foundational text in political theory and realpolitik',
      'Analyzed the nature of power, leadership, and statecraft',
      'Influenced modern political science and discussions on ethics in politics'
    ],
    lifeStory: 'niccolo-machiavelli.md',
    relatedPhilosophers: [15, 17, 27]
  },
  {
    id: 31,
    name: 'G.W.F. Hegel',
    backgroundColor: color1,
    sp_image: sp_GeorgWilhelmFriedrichHegel,
    ff_image: ff_GeorgWilhelmFriedrichHegel,
    epithet: 'Philosopher of Absolute Idealism',
    lifeSpan: '(1770-1831)',
    descriptiveWords: ['Idealist', 'Dialectical', 'Dialectical'],
    bestQuote: 'Genuine tragedies in the world are not conflicts between right and wrong. They are conflicts between two rights.',
    nationality: '🇩🇪 German',
    accomplishments: [
      'Developed a comprehensive philosophical framework in works like "Phenomenology of Spirit"',
      'Influenced discussions on history, politics, and reality through dialectical reasoning',
      'Shaped the development of German idealism and influenced later thinkers like Marx'
    ],
    lifeStory: 'georg-wilhelm-friedrich-hegel.md',
    relatedPhilosophers: [2, 5, 32]
  },
  {
    id: 32,
    name: 'Karl Marx',
    backgroundColor: color2,
    sp_image: sp_KarlMarx,
    ff_image: ff_KarlMarx,
    epithet: 'Revolutionary of the Proletariat',
    lifeSpan: '(1818-1883)',
    descriptiveWords: ['Communist', 'Economist', 'Visionary'],
    bestQuote: 'The philosophers have only interpreted the world, in various ways; the point, however, is to change it.',
    nationality: '🇩🇪 German',
    accomplishments: [
      'Co-authored "The Communist Manifesto," advocating for class struggle and revolution',
      'Developed the theory of historical materialism in "Das Kapital"',
      'Influenced political movements and ideologies worldwide'
    ],
    lifeStory: 'karl-marx.md',
    relatedPhilosophers: [2, 13, 31]
  },
  {
    id: 33,
    name: 'John Locke',
    backgroundColor: color3,
    sp_image: sp_JohnLocke,
    ff_image: ff_JohnLocke,
    epithet: 'Father of Classical Liberalism',
    lifeSpan: '(1632-1704)',
    descriptiveWords: ['Empiricist', 'Righteous', 'Liberal'],
    bestQuote: 'The end of law is not to abolish or restrain, but to preserve and enlarge freedom.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Developed the theory of mind, laying the groundwork for modern empiricism',
      'Authored "Two Treatises of Government," influencing political philosophy and liberalism',
      'Contributed to social contract theory and ideas of natural rights'
    ],
    lifeStory: 'john-locke.md',
    relatedPhilosophers: [6, 7, 10]
  },
  {
    id: 34,
    name: 'Ludwig Wittgenstein',
    backgroundColor: color4,
    sp_image: sp_LudwigWittgenstein,
    ff_image: ff_LudwigWittgenstein,
    epithet: 'Master of Language Games',
    lifeSpan: '(1889-1951)',
    descriptiveWords: ['Analytical', 'Linguistic', 'Logical'],
    bestQuote: 'The limits of my language mean the limits of my world.',
    nationality: '🇦🇹 Austrian',
    accomplishments: [
      'Authored "Tractatus Logico-Philosophicus," influencing analytical philosophy',
      'Explored the relationship between language and reality',
      'Contributed to the philosophy of mind, language, and mathematics'
    ],
    lifeStory: 'ludwig-wittgenstein.md',
    relatedPhilosophers: [8, 19, 28]
  },
  {
    id: 35,
    name: 'John Stuart Mill',
    backgroundColor: color5,
    sp_image: sp_JohnStuartMill,
    ff_image: ff_JohnStuartMill,
    epithet: 'Champion of Utilitarian Ethics',
    lifeSpan: '(1806-1873)',
    descriptiveWords: ['Utilitarian', 'Economist', 'Political'],
    bestQuote: 'A person may cause evil to others not only by his actions but by his inaction, and in either case he is justly accountable to them for the injury.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Developed the theory of utilitarianism, emphasizing the greatest happiness principle',
      'Authored "On Liberty," advocating for individual freedom and social progress',
      'Contributed to political philosophy, economics, and feminist thought'
    ],
    lifeStory: 'john-stuart-mill.md',
    relatedPhilosophers: [2, 24, 28]
  },
  {
    id: 36,
    name: 'Michel de Montaigne',
    backgroundColor: color6,
    sp_image: sp_MichelDeMontaigne,
    ff_image: ff_MichelDeMontaigne,
    epithet: 'Master of Self-Reflection',
    lifeSpan: '(1533-1592)',
    descriptiveWords: ['Skeptical', 'Essayist', 'Humanist'],
    bestQuote: 'A man who fears suffering is already suffering from what he fears.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Pioneered the essay as a literary form, exploring personal and philosophical topics',
      'Promoted skepticism and self-reflection in his works',
      'Influenced later writers and thinkers with his introspective style'
    ],
    lifeStory: 'michel-de-montaigne.md',
    relatedPhilosophers: [6, 25, 7]
  },
  {
    id: 37,
    name: 'Gilles Deleuze',
    backgroundColor: color7,
    sp_image: sp_GillesDeleuze,
    ff_image: ff_GillesDeleuze,
    epithet: 'Systems Thinker of Philosophical Concepts',
    lifeSpan: '(1925-1995)',
    descriptiveWords: ['Postmodern', 'Creative', 'Vitalist'],
    bestQuote: 'A concept is a brick. It can be used to build a courthouse of reason. Or it can be thrown through the window.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Developed new philosophical concepts in collaboration with Félix Guattari',
      'Authored "A Thousand Plateaus," influencing post-structuralist thought',
      'Explored the relationship between philosophy, art, and politics'
    ],
    lifeStory: 'gilles-deleuze.md',
    relatedPhilosophers: [12, 26, 25]
  },
  {
    id: 38,
    name: 'Seneca',
    backgroundColor: color8,
    sp_image: sp_Seneca,
    ff_image: ff_Seneca,
    epithet: 'Advisor of Emperors',
    lifeSpan: '(4 BC-65 AD)',
    descriptiveWords: ['Stoic', 'Ethical', 'Virtuous'],
    bestQuote: 'If you don\'t know what port you sail to, no wind is favorable',
    nationality: '🇮🇹 Roman',
    accomplishments: [
      'Authored numerous works on Stoic philosophy, including "Letters to Lucilius"',
      'Served as an advisor to Emperor Nero, navigating the complexities of Roman politics',
      'Explored themes of ethics, fate, and human resilience in his tragedies and essays'
    ],
    lifeStory: 'seneca.md',
    relatedPhilosophers: [1, 21, 10]
  },
  {
    id: 39,
    name: 'Mary Wollstonecraft',
    backgroundColor: color9,
    sp_image: sp_MaryWollstonecraft,
    ff_image: ff_MaryWollstonecraft,
    epithet: 'Mother of Feminism',
    lifeSpan: '(1759-1797)',
    descriptiveWords: ['Feminist', 'Visionary', 'Educational'],
    bestQuote: 'I do not wish them to have power over men; but over themselves.',
    nationality: '🇬🇧 British',
    accomplishments: [
      'Authored "A Vindication of the Rights of Woman," advocating for women’s education and equality',
      'Critiqued the societal norms that oppressed women during the Enlightenment period',
      'Influenced later feminist movements and discussions on gender equality'
    ],
    lifeStory: 'mary-wollstonecraft.md',
    relatedPhilosophers: [3, 19, 35]
  },
  {
    id: 40,
    name: 'Michel Foucault',
    backgroundColor: color10,
    sp_image: sp_MichelFoucault,
    ff_image: ff_MichelFoucault,
    epithet: 'Analyst of Power Structures',
    lifeSpan: '(1926-1984)',
    descriptiveWords: ['Postmodern', 'Political', 'Historian'],
    bestQuote: 'Knowledge is not for knowing: knowledge is for cutting.',
    nationality: '🇫🇷 French',
    accomplishments: [
      'Analyzed the relationships between power, knowledge, and social institutions in works like "Discipline and Punish"',
      'Developed theories on biopolitics and governmentality',
      'Influenced a wide range of fields, including sociology, anthropology, and cultural studies'
    ],
    lifeStory: 'michel-foucault.md',
    relatedPhilosophers: [12, 28, 37]
  },
  {
    id: 41,
    name: 'bell hooks',
    backgroundColor: color1,
    sp_image: sp_bellhooks,
    ff_image: ff_bellhooks,
    epithet: 'Champion of Love and Justice',
    lifeSpan: '(1952-2021)',
    descriptiveWords: ['Feminist', 'Visionary', 'Writer'],
    bestQuote: 'Knowing how to be solitary is central to the art of loving. When we can be alone, we can be with others without using them as a means of escape.',
    nationality: '🇺🇸 American',
    accomplishments: [
      'Authored over 30 books on race, feminism, and culture, including seminal works like "Ain’t I a Woman?" and "All About Love"',
      'Developed the concept of "intersectionality" in feminist theory, exploring the interconnected nature of social categorizations',
      'Recognized as one of TIME’s "100 Women of the Year" in 2020 for her influential contributions to feminist thought and social justice'
    ],
    lifeStory: 'bell-hooks.md',
    relatedPhilosophers: [3, 39, 27, 19]
  },
];

// This list of differnet affinities holds the order of the rendering of the different above philosophers as well as the ordering of the different groups of affinities
export const affinities = [
  {
    name: 'Build Character with Wise Life Principles',
    philosophers: [1, 18, 29, 20, 15, 4, 2, 19, 30, 10, 21, 22, 36, 17, 38]
  },
  {
    name: 'Rebels and Challengers Who Did It Their Way',
    philosophers: [12, 2, 3, 32, 9, 14, 23, 24, 39, 37, 18, 40, 29, 41, 30]
  },
  {
    name: 'Women in Philosophy',
    philosophers: [39, 3, 16, 41, 19, 18, 20, 22, 25, 24]
  },
  {
    name: 'Wisdom on Meaning, Purpose and Overcoming the Existential Crisis',
    philosophers: [11, 14, 18, 23, 15, 36, 3, 2, 31, 22]
  },
  {
    name: 'Political Philosophy',
    philosophers: [16, 26, 33, 32, 17, 35, 40, 4, 15, 30, 24, 31, 23]
  },
  {
    name: 'Ethics and Morality',
    philosophers: [15, 22, 35, 5, 20, 4, 17, 9, 26, 7, 28, 16, 36, 25, 19, 31, 3, 27, 10]
  },
  {
    name: 'Human Nature',
    philosophers: [13, 17, 7, 41, 27, 40, 34, 14, 36, 4, 3, 31, 10, 20, 37, 9, 19, 23, 28, 18]
  },
  {
    name: 'The Mind, Understanding and Rationality',
    philosophers: [6, 34, 25, 5, 8, 7, 17, 31, 15, 13, 37, 27, 22]
  },
  {
    name: 'Ancient Wisdom',
    philosophers: [10, 4, 9, 17, 12, 15, 28, 38, 21, 1]
  },
  {
    name: 'Philosophy of Religion',
    philosophers: [11, 2, 7, 18, 5, 17, 6, 31, 15, 13, 34, 41, 36, 14, 10, 4, 40]
  },
  {
    name: 'Perspectives on Art and Aesthetics',
    philosophers: [19, 37, 5, 2, 13, 29, 17, 15, 7, 23, 3, 34, 14, 36]
  },

]
